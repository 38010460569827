import { Klass } from "@/lib/transforms"
import { Edition } from "./Edition"
import { Grade } from "./Grade"

export class Clazz {
  id!: number
  school_id!: number
  name!: string
  code!: string
  fullname!: string
  import_no: string
  student_authcode: string
  leaved_for_clazz!: boolean
  available_studentships_count!: number
  school_name!: string
  school_region_name!: string
  site_id!: number
  state!: string
  term_year!: number
  graduation_year: number
  parent_id: number | null
  grade_id: number
  fiction: boolean

  @Klass(Date) archived_at: Date | null
  @Klass(Date) created_at: Date | null
  @Klass(Date) updated_at: Date | null
  @Klass(Date) level_up_at: Date | null
  @Klass(Grade) grade: Grade
}

export class ClazzInfo extends Clazz {
  @Klass(Edition) editions: Edition[]
  available_teacherships_count: number
}

import { cva } from 'class-variance-authority'

export type PresetId = 'standard' | 'light' | 'outline' | 'ghost'

const base = `
  inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors
  focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring
  disabled:pointer-events-none disabled:opacity-50
`

const size = {
  xs: 'h-6 rounded px-2 text-xs',
  sm: 'h-7 rounded px-3 text-xs',
  default: 'h-9 px-4 py-2 text-sm',
  lg: 'h-10 rounded-md px-8',

  icon: 'h-9 w-9',
  "icon-sm": 'h-7 w-7 text-sm',
}

export const ButtonVariances = {
  standard: cva(base, {
    variants: {
      variant: {
        primary: 'bg-primary text-primary-foreground hover:bg-primary/80',
        secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/80',
        muted: 'bg-muted text-muted-foreground hover:bg-muted/80',
        tint: 'bg-[color:hsl(var(--color-tint))] text-primary-foreground hover:bg-[color:hsl(var(--color-tint)/0.8)]',
      },
      size,
    },
    defaultVariants: {
      variant: 'primary',
      size: 'default',
    }
  }),

  light: cva(base, {
    variants: {
      variant: {
        primary: 'bg-primary/20 text-primary hover:bg-primary/80 hover:text-primary-foreground',
        secondary: 'bg-secondary/20 text-secondary hover:bg-secondary/80 hover:text-secondary-foreground',
        destructive: 'bg-destructive/20 text-destructive hover:bg-destructive/80 hover:text-destructive-foreground',
      },
      size,
    },
    defaultVariants: {
      variant: 'primary',
      size: 'default',
    }
  }),

  outline: cva(base, {
    variants: {
      variant: {
        primary: 'border border-primary text-primary hover:text-primary-foreground hover:bg-primary/80',
        secondary: 'border border-secondary text-secondary hover:text-secondary-foreground hover:bg-secondary/80',
        destructive: 'border border-destructive text-destructive hover:text-destructive-foreground hover:bg-destructive/80',
        muted: 'border border-muted text-muted hover:text-muted-foreground hover:bg-muted/80'
      },
      size,
    },
    defaultVariants: {
      variant: 'primary',
      size: 'default',
    }
  }),

  ghost: cva(base, {
    variants: {
      variant: {
        primary: 'text-primary hover:text-primary-foreground hover:bg-primary/60',
        secondary: 'text-secondary hover:text-secondary-foreground hover:bg-secondary/60',
        destructive: 'text-destructive hover:text-destructive-foreground hover:bg-destructive/60',
        muted: 'text-muted hover:text-muted-foreground hover:bg-muted/50',
      },
      size,
    },
    defaultVariants: {
      variant: 'primary',
      size: 'default',
    }
  }),
} satisfies Record<PresetId, any>

